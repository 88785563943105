<template>
  <div class="card card-stretch mb-4">
    <div class="card-header border-0">
      <div class="d-flex">
        <!-- if isDraggable -->
        <div v-if="isDraggable" class="lot-drag-handle handle me-2" style="cursor: grab">
          <svg
            class="align-self-center"
            style="width: 15px; height: 100%; display: block"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
              fill-opacity="0.2"
            />
          </svg>
        </div>
        <div class="d-flex">
          <h3 class="mt-5">
            <span class="ms-2">
              <IconButton
                iconName="box-arrow-up-right"
                @click="
                  this.$router.push(
                    todo.project ? { name: 'project.todos', params: { id: todo.project.id } } : { name: 'todos' }
                  )
                "
              />
            </span>
            <span class="card-label fw-bolder text-dark fs-3 me-0">{{ todo.title }}</span>
            <span v-if="assignedToCurrentUser">
              <span v-if="todo.project" class="ms-2 text-muted fs-4 fw-normal">{{ todo.project.name }} </span>
              <span v-if="!todo.project" class="ms-2 text-muted fs-4 fw-normal">Todo d'agence </span>
            </span>
          </h3>
        </div>
      </div>
      <div v-if="!assignedToCurrentUser" class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-light">
          <li class="nav-item">
            <EditButton :disabled="readOnly" @click="editTodo"></EditButton>
          </li>
          <li class="nav-item">
            <DeleteButton :disabled="readOnly" @click="showDeleteTodo"></DeleteButton>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body pt-5 pb-0">
      <div v-if="loading">
        <div class="d-flex justify-content-center">
          <div class="spinner spinner-primary spinner-center"></div>
        </div>
      </div>
      <div v-if="!getItems().length && !loading" class="text-center w-25 mx-auto">
        <TodoEmptyState
          buttonMessage="Ajouter une tâche"
          image="/assets/media/svg/empty-states/empty-todo.svg"
          message="Aucune tâche"
          @add="addTodoItem"
        />
      </div>
      <draggable
        :component-data="{
          tag: 'div',
          type: 'transition-group',
          name: !dragging ? 'flip-list' : null,
        }"
        :disabled="!enabled || !isDraggable || readOnly"
        :list="getItems()"
        class="list-group mb-8"
        handle=".handle"
        item-key="id"
        v-bind="dragOptions"
        @add="onAdd"
        @end="
          dragging = false;
          onDropped();
        "
        @start="dragging = true"
      >
        <template #item="{ element }">
          <div :class="{ 'not-draggable': !enabled || !isDraggable }" class="list-group-item">
            <TodoItem
              :assignedToCurrentUser="assignedToCurrentUser"
              :canevaId="this.canevaId"
              :filterAssignedTo="filterAssignedTo"
              :filterProject="filterProject"
              :is-draggable="isDraggable"
              :project-id="projectId"
              :readOnly="this.readOnly"
              :todo-id="todo.id"
              :todo-item="element"
            />
          </div>
        </template>
      </draggable>
      <TodoItemForm
        v-if="showTodoItemForm"
        :assignedToCurrentUser="assignedToCurrentUser"
        :auto-assigned-to-id="assignedToCurrentUser ? currentUser.id : null"
        :canevaId="canevaId"
        :filterAssignedTo="filterAssignedTo"
        :filterProject="filterProject"
        :last-order="getLastOrder()"
        :projectId="projectId"
        :todo-id="todo.id"
        class="mb-8"
        @close="reset"
      />
      <button
        v-if="!showTodoItemForm && !assignedToCurrentUser"
        :disabled="readOnly"
        class="btn btn-primary mb-5"
        type="button"
        @click="addTodoItem"
      >
        Ajouter une tâche
      </button>
      <DeleteTodoConfirmModal
        v-if="showTodoDeleteModal"
        :assignedToCurrentUser="assignedToCurrentUser"
        :canevaId="canevaId"
        :filterAssignedTo="filterAssignedTo"
        :filterProject="filterProject"
        :projectId="projectId"
        :todo="todo"
        @close="reset"
      />
      <TodoFormModal
        v-if="showTodoEditModal"
        :assignedToCurrentUser="assignedToCurrentUser"
        :canevaId="canevaId"
        :filterAssignedTo="filterAssignedTo"
        :filterProject="filterProject"
        :projectId="projectId"
        :todo="todo"
        @close="reset"
      />
    </div>
  </div>
</template>

<script>
import DeleteButton from "@/views/_core/components/DeleteButton.vue";
import EditButton from "@/views/_core/components/EditButton.vue";
import IconButton from "@/views/_core/components/IconButton.vue";
import { CURRENT_USER_QUERY } from "@/views/users/data/users_graphql";
import draggable from "vuedraggable";
import { BULK_UPDATE_TODO_ITEM_ORDER, GET_TODOS } from "../../../data/todos_graphql";
import TodoFormModal from "../../todo-form/TodoFormModal.vue";
import TodoItemForm from "../../todo-form/TodoItemForm.vue";
import DeleteTodoConfirmModal from "./DeleteTodoConfirmModal.vue";
import TodoEmptyState from "./TodoEmptyState.vue";
import TodoItem from "./TodoItem.vue";
export default {
  name: "Todo-List",
  props: {
    todo: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    projectId: {
      type: String,
      required: false,
    },
    canevaId: {
      type: String,
      required: false,
    },
    filterAssignedTo: {
      type: Array,
    },
    filterProject: {
      type: Array,
    },
    isDraggable: {
      type: Boolean,
      required: false,
      default: true,
    },
    assignedToCurrentUser: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    TodoItem,
    TodoFormModal,
    DeleteTodoConfirmModal,
    TodoItemForm,
    EditButton,
    DeleteButton,
    draggable,
    IconButton,
    TodoEmptyState,
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
    },
  },
  data() {
    return {
      showTodoItemForm: false,
      showTodoEditModal: false,
      showTodoDeleteModal: false,
      dragging: false,
      enabled: true,
      localItems: [],
      lastStringifiedItems: "",
    };
  },
  methods: {
    reset() {
      this.showTodoItemForm = false;
      this.showTodoEditModal = false;
      this.showTodoDeleteModal = false;
    },
    addTodoItem() {
      this.showTodoItemForm = true;
    },
    editTodo() {
      this.showTodoEditModal = true;
    },
    showDeleteTodo() {
      this.showTodoDeleteModal = true;
    },
    getLastOrder() {
      if (!this.todo.todoItems.length) {
        return 0;
      }
      return this.todo.todoItems[this.todo.todoItems.length - 1].order;
    },
    onDropped() {
      this.$apollo.mutate({
        mutation: BULK_UPDATE_TODO_ITEM_ORDER,
        variables: {
          todoItems: [
            ...this.localItems.map((todo, index) =>
              JSON.stringify({
                id: todo.id,
                order: index,
                todoId: this.todo.id,
              })
            ),
          ],
        },
        refetchQueries: [
          {
            query: GET_TODOS,
            variables: {
              projectId: this.projectId,
              assignedToCurrentUser: this.assignedToCurrentUser,
              filterAssignedTo: this.filterAssignedTo,
              filterProject: this.filterProject,
            },
          },
        ],
      });
    },
    onAdd() {
      // Will save the new order and new todoId
      this.onDropped();
    },
    getItems() {
      // Stringified items without the order and updatedAt fields
      const stringifiedItems = JSON.stringify(
        this.todo.todoItems.map((item) => {
          // eslint-disable-next-line no-unused-vars
          const { order, updatedAt, ...rest } = item;
          return rest;
        })
      );

      if (this.lastStringifiedItems !== stringifiedItems) {
        this.localItems = [...this.todo.todoItems];
        this.lastStringifiedItems = stringifiedItems;
      }
      return this.localItems;
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "todoItems",
        ghostClass: "ghost",
      };
    },
  },
};
</script>
