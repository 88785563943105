<template>
  <div class="row g-3 print-hidden">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="card-title align-items-start flex-column pb-3">
          <span v-if="!assignedToCurrentUser" class="card-label fw-bolder text-dark fs-2">Todos</span>
          <span v-if="assignedToCurrentUser" class="card-label fw-bolder text-dark fs-2">Mes Tâches</span>
        </h3>
        <div v-if="!assignedToCurrentUser" class="card-toolbar ms-3">
          <ul class="nav nav-pills nav-pills-sm nav-light">
            <li class="d-flex ms-auto justify-content-end mb-2">
              <div class="me-2">
                <UserMultiSelect v-model="filterAssignedTo" :options="this.userFilterOptions"></UserMultiSelect>
              </div>
              <div class="me-2">
                <ProjectMultiSelect v-model="filterProject" :options="this.projectFilterOptions"></ProjectMultiSelect>
              </div>
            </li>
            <li class="d-flex ms-auto justify-content-end mb-2">
              <button class="nav-link btn btn-secondary btn-sm fw-bolder me-2" @click="canevaPath">
                Voir les canevas
              </button>
              <button class="nav-link btn btn-primary btn-sm fw-bolder me-2" @click="addTodo">Ajouter une Todo</button>
            </li>
          </ul>
        </div>
      </div>
      <TodosList
        :assignedToCurrentUser="assignedToCurrentUser"
        :canevaId="null"
        :emptyStateType="assignedToCurrentUser ? 'card' : 'image'"
        :filterAssignedTo="filterAssignedTo"
        :filterProject="filterProject"
        :initialItems="todosByProject"
        :loading="loading"
        :projectId="projectId"
        @addTodo="addTodo"
      />
    </div>
    <TodoFormModal
      v-if="showTodoModal"
      :assignedToCurrentUser="assignedToCurrentUser"
      :canevaId="null"
      :filterAssignedTo="filterAssignedTo"
      :filterProject="filterProject"
      :last-order="getLastOrder()"
      :projectId="projectId"
      @close="reset"
    />
  </div>
</template>

<script>
import { GET_TODOS } from "../../data/todos_graphql";
import TodoFormModal from "../todo-form/TodoFormModal.vue";
import TodosList from "./components/TodosList.vue";
import { ALL_PROJECTS_SIMPLE_QUERY, CURRENT_USER_QUERY } from "@/graphql/graphql";
import UserMultiSelect from "@/views/users/presentation/user-select/UserMultiSelect.vue";
import ProjectMultiSelect from "@/views/project/ProjectMultiSelect.vue";
export default {
  name: "TodosPage",
  components: {
    UserMultiSelect,
    ProjectMultiSelect,
    TodoFormModal,
    TodosList,
  },
  props: {
    // Used to show own user todos on the dashboard
    assignedToCurrentUser: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    this.projectId = this.$route.params.id;
  },
  data() {
    return {
      projectId: undefined,
      todosByProject: [],
      showTodoModal: false,
      filterAssignedTo: [],
      filterProject: [],
      projects: [],
      currentUser: {
        org: {
          team: [],
        },
      },
    };
  },
  watch: {
    $route(to) {
      this.projectId = to.params.id;
    },
  },
  methods: {
    reset() {
      this.showTodoModal = false;
    },
    addTodo() {
      this.showTodoModal = true;
    },
    getLastOrder() {
      if (!this.todosByProject.length) {
        return 0;
      }
      return this.todosByProject[this.todosByProject.length - 1].order;
    },
    canevaPath() {
      this.$router.push({ name: "canevas" });
    },
  },
  computed: {
    loading() {
      return this.$apollo.loading;
    },
    userFilterOptions() {
      return [
        { id: "00000000-0000-0000-0000-000000000000", lastName: "", firstName: "Non attribué" },
        ...this.currentUser.org.team,
      ];
    },
    projectFilterOptions() {
      return [
        { id: "00000000-0000-0000-0000-000000000000", name: "Todo d'agence" },
        ...this.projects.filter((p) => !p.archived),
      ];
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
    },
    projects: {
      query: ALL_PROJECTS_SIMPLE_QUERY,
    },
    todosByProject: {
      query: GET_TODOS,
      variables() {
        return {
          projectId: this.projectId,
          assignedToCurrentUser: this.assignedToCurrentUser,
          filterAssignedTo: this.filterAssignedTo,
          filterProject: this.filterProject,
        };
      },
      fetchPolicy: "cache-and-network",
    },
  },
};
</script>
