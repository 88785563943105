<template>
  <div>
    <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
      <div class="d-flex pt-2 pt-sm-0">
        <!-- if isDraggable -->
        <div v-if="isDraggable" class="lot-drag-handle handle me-2" style="cursor: grab">
          <svg
            class="align-self-center"
            style="width: 15px; height: 100%; display: block"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
              fill-opacity="0.2"
            />
          </svg>
        </div>
        <div class="form-check">
          <input
            :id="todoItem.id"
            :checked="todoItem.isDone"
            :disabled="readOnly"
            :value="todoItem.isDone"
            class="form-check-input"
            type="checkbox"
            @change="markAsDone()"
          />
          <label
            :class="{ 'text-decoration-line-through': todoItem.isDone }"
            :for="todoItem.id"
            class="form-check-label"
          >
            {{ todoItem.name }}
          </label>
        </div>
      </div>
      <div class="flex-shrink-0">
        <span v-if="todoItem.assignedTo" class="badge badge-secondary me-4 text-gray-600">
          {{ todoItem.assignedTo.firstName + " " + todoItem.assignedTo.lastName }}
        </span>
        <span v-if="todoItem.dueDate" class="badge badge-secondary me-4 text-gray-600">
          {{ getFormattedDueDate(todoItem.dueDate) }}
        </span>
        <IconButton
          v-if="!!todoItem.description"
          v-tooltip:bottom="todoItem.description.substring(0, 100)"
          iconName="file-earmark-text"
          @click="editTodoItem"
        />
        <IconButton
          v-if="todoItem.todoItems.length"
          v-tooltip:bottom="'Contient au moins une sous-tâche'"
          iconName="list"
          @click="editTodoItem"
        />
        <FileCounterForTodoItem v-if="!basicButtons" :todoItemId="todoItem.id" @click="openFiles" />
        <EditButton @click="editTodoItem"></EditButton>
        <DeleteButton v-if="!readOnly" @click="showDeleteTodoItem"></DeleteButton>
      </div>
    </div>

    <!-- Edit form -->
    <TodoItemFormModal
      v-if="showTodoItemForm"
      :assignedToCurrentUser="assignedToCurrentUser"
      :basic-inputs="true"
      :canevaId="canevaId"
      :filterAssignedTo="filterAssignedTo"
      :filterProject="filterProject"
      :projectId="projectId"
      :readOnly="readOnly"
      :todo-id="todoId"
      :todoItem="todoItem"
      class="mt-4"
      @close="reset"
    />
    <!-- Delete modal -->
    <DeleteTodoItemConfirmModal
      v-if="showTodoItemDeleteModal"
      :assignedToCurrentUser="assignedToCurrentUser"
      :canevaId="canevaId"
      :filterAssignedTo="filterAssignedTo"
      :filterProject="filterProject"
      :projectId="projectId"
      :todoItem="todoItem"
      @close="reset"
    />
    <!-- Files modal -->
    <FilesForTodoItemModal v-if="showFilesModal" :todoItemId="todoItem.id" @close="reset" />
  </div>
</template>

<script>
import DeleteButton from "@/views/_core/components/DeleteButton.vue";
import EditButton from "@/views/_core/components/EditButton.vue";
import IconButton from "@/views/_core/components/IconButton.vue";
import FileCounterForTodoItem from "@/views/documents/presentation/file-counter-for-todo-item/FileCounterForTodoItem.vue";
import FilesForTodoItemModal from "@/views/documents/presentation/file-list-modal/FilesForTodoItemModal.vue";
import dayjs from "dayjs";
import { UPDATE_TODO_ITEM } from "../../../data/todos_graphql";
import TodoItemFormModal from "../../todo-form/TodoItemFormModal.vue";
import DeleteTodoItemConfirmModal from "./DeleteTodoItemConfirmModal.vue";
export default {
  name: "TodoItem",
  props: {
    todoItem: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    todoId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: false,
    },
    filterAssignedTo: {
      type: Array,
    },
    filterProject: {
      type: Array,
    },
    canevaId: {
      type: String,
      required: false,
    },
    isDraggable: {
      type: Boolean,
      required: false,
      default: true,
    },
    assignedToCurrentUser: {
      type: Boolean,
      required: false,
      default: false,
    },
    basicButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DeleteTodoItemConfirmModal,
    TodoItemFormModal,
    EditButton,
    DeleteButton,
    FileCounterForTodoItem,
    FilesForTodoItemModal,
    IconButton,
  },
  data() {
    return {
      showTodoItemDeleteModal: false,
      showTodoItemForm: false,
      showFilesModal: false,
    };
  },
  methods: {
    reset() {
      this.$emit("close");
      this.showTodoItemDeleteModal = false;
      this.showTodoItemForm = false;
      this.showFilesModal = false;
    },
    showDeleteTodoItem() {
      this.showTodoItemDeleteModal = true;
    },
    editTodoItem() {
      this.showTodoItemForm = true;
    },
    markAsDone() {
      this.$apollo.mutate({
        mutation: UPDATE_TODO_ITEM,
        variables: {
          id: this.todoItem.id,
          isDone: !this.todoItem.isDone,
        },
      });
    },
    openFiles() {
      this.showFilesModal = true;
    },
    getFormattedDueDate(date) {
      return dayjs(date).format("DD MMM YY");
    },
    addTodoItem() {
      this.$emit("add-todo-item");
    },
  },
  mounted() {},
};
</script>
